@font-face {
  font-family: font;
  src: url(/public/outfit.ttf);
}

.app {
  align-items: stretch;
  display: flex;
  flex-flow: column;
  height: 100vh;
  font-family: font;
}

.header {
  background: linear-gradient(45deg, rgba(0,212,255,1) 14%, rgba(0,255,175,1) 100%);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
  color: #ffffff;
  padding: 24px;
  background-color: #bbbbbb;
  text-shadow: 0px 1px rgba(0,0,0,0.25);
  justify-content: space-between;
  line-height: 120%;
  font-size: 200%;
  display: flex;
}

.footer .image {
  background-image: url(/public/peek.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 64px;
  height: 64px;
}

.footer {
  background: linear-gradient(90deg, rgba(255,0,190,1) 14%, rgba(254,255,0,1) 100%);
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.35);
  color: #ffffff;
  padding: 24px;
  background-color: #bbbbbb;
  text-shadow: 0px 1px rgba(0,0,0,0.25);
  line-height: 120%;
  text-align: center;
  font-size: 125%;
}

.header .details .name {
  font-size: 150%;
  font-weight: bold;
  margin-bottom: 8px;
}

.header .details .title {
  font-size: 75%;
}

.header .details .contact {
  opacity: 0.85;
  font-size: 55%;
}

.header a,
.footer a {
  color: #ffffff;
  font-weight: bold;
}

.contents {
  margin: 25px;
}

.contents header {
  font-size: 175%;
  margin-top: 35px;
  margin-bottom: 35px;
}

.contents section {
  margin-bottom: 48px;
}

.contents li {
  margin-bottom: 24px;
}

.experience .info {
  font-size: 125%;
  color: #444444;
  margin-bottom: 32px;
}

.experience .title,
.experience .company {
  font-weight: bold;
  color: initial;
}

ul {
  list-style: none;
  padding-left: 0px;
}

li {
  border-left: 2px solid rgba(0, 0, 0, 0.25);
  padding-left: 8px;
  margin-left: 16px;
}

@media (max-width: 480px) {
  ul {
    padding-left: 0px;
  }
  
  li {
    margin-left: 0px;
  }
}
